import i18n from '@/libs/i18n'

export default [
    { key: null, value: i18n.t('noSelect') },
    { key: "ARTS", value: i18n.t('ARTS') },
    { key: "BOOKS", value: i18n.t('BOOKS') },
    { key: "DESIGN", value: i18n.t('DESIGN') },
    { key: "FASHION_BEAUTY", value: i18n.t('FASHION_&_BEAUTY') },
    { key: "FOOD", value: i18n.t('FOOD') },
    { key: "PERFORMING_ARTS", value: i18n.t('PERFORMING_ARTS') },
    { key: "VISUAL_ARTS", value: i18n.t('VISUAL_ARTS') },
    { key: "BUSINESS", value: i18n.t('BUSINESS') },
    { key: "CAREERS", value: i18n.t('CAREERS') },
    { key: "ENTREPRENEURSHIP", value: i18n.t('ENTREPRENEURSHIP') },
    { key: "INVESTING", value: i18n.t('INVESTING') },
    { key: "MANAGEMENT", value: i18n.t('MANAGEMENT') },
    { key: "MARKETING", value: i18n.t('MARKETING') },
    { key: "NON_PROFIT", value: i18n.t('NON_PROFIT') },
    { key: "COMEDY", value: i18n.t('COMEDY') },
    { key: "COMEDY_INTERVIEWS", value: i18n.t('COMEDY_INTERVIEWS') },
    { key: "IMPROV", value: i18n.t('IMPROV') },
    { key: "STAND_UP", value: i18n.t('STAND_UP') },
    { key: "EDUCATION", value: i18n.t('EDUCATION') },
    { key: "COURSES", value: i18n.t('COURSES') },
    { key: "HOW_TO", value: i18n.t('HOW_TO') },
    { key: "LANGUAGE_LEARNING", value: i18n.t('LANGUAGE_LEARNING') },
    { key: "SELF_IMPROVEMENT", value: i18n.t('SELF_IMPROVEMENT') },
    { key: "FICTION", value: i18n.t('FICTION') },
    { key: "COMEDY_FICTION", value: i18n.t('COMEDY_FICTION') },
    { key: "DRAMA", value: i18n.t('DRAMA') },
    { key: "SCIENCE_FICTION", value: i18n.t('SCIENCE_FICTION') },
    { key: "GOVERNMENT", value: i18n.t('GOVERNMENT') },
    { key: "HISTORY", value: i18n.t('HISTORY') },
    { key: "HEALTH_FITNESS", value: i18n.t('HEALTH_&_FITNESS') },
    { key: "ALTERNATIVE_HEALTH", value: i18n.t('ALTERNATIVE_HEALTH') },
    { key: "FITNESS", value: i18n.t('FITNESS') },
    { key: "MEDICINE", value: i18n.t('MEDICINE') },
    { key: "MENTAL_HEALTH", value: i18n.t('MENTAL_HEALTH') },
    { key: "NUTRITION", value: i18n.t('NUTRITION') },
    { key: "SEXUALITY", value: i18n.t('SEXUALITY') },
    { key: "KIDS_FAMILY", value: i18n.t('KIDS_&_FAMILY') },
    { key: "EDUCATION_FOR_KIDS", value: i18n.t('EDUCATION_FOR_KIDS') },
    { key: "PARENTING", value: i18n.t('PARENTING') },
    { key: "PETS_ANIMALS", value: i18n.t('PETS_&_ANIMALS') },
    { key: "STORIES_FOR_KIDS", value: i18n.t('STORIES_FOR_KIDS') },
    { key: "LEISURE", value: i18n.t('LEISURE') },
    { key: "ANIMATION_MANGA", value: i18n.t('ANIMATION_&_MANGA') },
    { key: "AUTOMOTIVE", value: i18n.t('AUTOMOTIVE') },
    { key: "AVIATION", value: i18n.t('AVIATION') },
    { key: "CRAFTS", value: i18n.t('CRAFTS') },
    { key: "GAMES", value: i18n.t('GAMES') },
    { key: "HOBBIES", value: i18n.t('HOBBIES') },
    { key: "HOME_GARDEN", value: i18n.t('HOME_&_GARDEN') },
    { key: "VIDEO_GAMES", value: i18n.t('VIDEO_GAMES') },
    { key: "MUSIC", value: i18n.t('MUSIC') },
    { key: "MUSIC_COMMENTARY", value: i18n.t('MUSIC_COMMENTARY') },
    { key: "MUSIC_HISTORY", value: i18n.t('MUSIC_HISTORY') },
    { key: "MUSIC_INTERVIEWS", value: i18n.t('MUSIC_INTERVIEWS') },
    { key: "NEWS", value: i18n.t('NEWS') },
    { key: "BUSINESS_NEWS", value: i18n.t('BUSINESS_NEWS') },
    { key: "DAILY_NEWS", value: i18n.t('DAILY_NEWS') },
    { key: "ENTERTAINMENT_NEWS", value: i18n.t('ENTERTAINMENT_NEWS') },
    { key: "NEWS_COMMENTARY", value: i18n.t('NEWS_COMMENTARY') },
    { key: "POLITICS", value: i18n.t('POLITICS') },
    { key: "SPORTS_NEWS", value: i18n.t('SPORTS_NEWS') },
    { key: "TECH_NEWS", value: i18n.t('TECH_NEWS') },
    { key: "RELIGION_SPIRITUALITY", value: i18n.t('RELIGION_&_SPIRITUALITY') },
    { key: "BUDDHISM", value: i18n.t('BUDDHISM') },
    { key: "CHRISTIANITY", value: i18n.t('CHRISTIANITY') },
    { key: "HINDUISM", value: i18n.t('HINDUISM') },
    { key: "ISLAM", value: i18n.t('ISLAM') },
    { key: "JUDAISM", value: i18n.t('JUDAISM') },
    { key: "RELIGION", value: i18n.t('RELIGION') },
    { key: "SPIRITUALITY", value: i18n.t('SPIRITUALITY') },
    { key: "SCIENCE", value: i18n.t('SCIENCE') },
    { key: "ASTRONOMY", value: i18n.t('ASTRONOMY') },
    { key: "CHEMISTRY", value: i18n.t('CHEMISTRY') },
    { key: "EARTH_SCIENCES", value: i18n.t('EARTH_SCIENCES') },
    { key: "LIFE_SCIENCES", value: i18n.t('LIFE_SCIENCES') },
    { key: "MATHEMATICS", value: i18n.t('MATHEMATICS') },
    { key: "NATURAL_SCIENCES", value: i18n.t('NATURAL_SCIENCES') },
    { key: "NATURE", value: i18n.t('NATURE') },
    { key: "PHYSICS", value: i18n.t('PHYSICS') },
    { key: "SOCIAL_SCIENCES", value: i18n.t('SOCIAL_SCIENCES') },
    { key: "SOCIETY_CULTURE", value: i18n.t('SOCIETY_&_CULTURE') },
    { key: "DOCUMENTARY", value: i18n.t('DOCUMENTARY') },
    { key: "PERSONAL_JOURNALS", value: i18n.t('PERSONAL_JOURNALS') },
    { key: "PHILOSOPHY", value: i18n.t('PHILOSOPHY') },
    { key: "PLACES_TRAVEL", value: i18n.t('PLACES_&_TRAVEL') },
    { key: "RELATIONSHIPS", value: i18n.t('RELATIONSHIPS') },
    { key: "SPORTS", value: i18n.t('SPORTS') },
    { key: "BASEBALL", value: i18n.t('BASEBALL') },
    { key: "BASKETBALL", value: i18n.t('BASKETBALL') },
    { key: "CRICKET", value: i18n.t('CRICKET') },
    { key: "FANTASY_SPORTS", value: i18n.t('FANTASY_SPORTS') },
    { key: "FOOTBALL", value: i18n.t('FOOTBALL') },
    { key: "GOLF", value: i18n.t('GOLF') },
    { key: "HOCKEY", value: i18n.t('HOCKEY') },
    { key: "RUGBY", value: i18n.t('RUGBY') },
    { key: "RUNNING", value: i18n.t('RUNNING') },
    { key: "SOCCER", value: i18n.t('SOCCER') },
    { key: "SWIMMING", value: i18n.t('SWIMMING') },
    { key: "TENNIS", value: i18n.t('TENNIS') },
    { key: "VOLLEYBALL", value: i18n.t('VOLLEYBALL') },
    { key: "WILDERNESS", value: i18n.t('WILDERNESS') },
    { key: "WRESTLING", value: i18n.t('WRESTLING') },
    { key: "TECHNOLOGY", value: i18n.t('TECHNOLOGY') },
    { key: "TRUE_CRIME", value: i18n.t('TRUE_CRIME') },
    { key: "TV_FILM", value: i18n.t('TV_&_FILM') },
    { key: "AFTER_SHOWS", value: i18n.t('AFTER_SHOWS') },
    { key: "FILM_HISTORY", value: i18n.t('FILM_HISTORY') },
    { key: "FILM_INTERVIEWS", value: i18n.t('FILM_INTERVIEWS') },
    { key: "FILM_REVIEWS", value: i18n.t('FILM_REVIEWS') },
    { key: "TV_REVIEWS", value: i18n.t('TV_REVIEWS') }
]