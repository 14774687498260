<template>
  <section id="contentsSelector">
    <b-row class="m-2">
      <b-col style="text-align: start;">
        <h1>{{ $t('selectSlider') }}</h1>
        <h5 class="text-primary">
          {{ $t('platfSlider') }}
        </h5>
      </b-col>
      <b-col style="text-align: end;">
        <b-button

          variant="success"
          :disabled="id?false:true"
          @click="seleccionar"
        >
          {{ $t('select') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          selectable
          select-mode="range"
          fixed
          responsive
          hover
          :sticky-header="true"
          :items="response"
          :fields="tableColumns"
          @row-selected="onRowSelected"
        >
          <template #cell(name)="data">
            <b-row class="justify-content-center">
              <p>{{ data.item.node.name }}</p>
            </b-row>
          </template>
          <template #cell(slideType)="data">
            <b-row class="justify-content-center">
              <p>
                {{ type[data.item.node.slideType] }}
              </p>
            </b-row>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BTable,

  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BTable,

  },
  props: {
    code: {
      type: String,
      default: '',
    },
    seleccionado: { type: Object, default: null },
  },
  data() {
    return {
      selected: null,
      id: null,
      headers: {},
      userData: getUserData(),
      name: null,
      response: null,
      type: {
        SHO: this.$t('Shop'),
        CON: this.$t('Contents'),
        URL: 'URL',
        CAT: this.$t('purchases.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
      },
      tableColumns: [
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'slideType', label: this.$t('type') },
      ],
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allSlider()
  },
  methods: {
    seleccionar() {
      this.$emit('sliderData', [this.id, this.name])
    },
    onRowSelected(data) {
      this.id = data[0].node.id
      this.name = data[0].node.name
    },
    allSlider() {
      const { headers } = this
      if (this.seleccionado != null) {
        this.selected = this.seleccionado
      }
      axios
        .post('', {
          query: `
          {
            slider(isRoot:false, client:"${getUserData().profile.client.id}") {
              edges {
                node {
                  id
                  name
                  slideType
                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.slider.edges
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style>
#card {
  background-color: aliceblue;
}

.td {
  width: 50px;
  height: 100px;
}

.folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

.dropContainer {
  border: 3px dashed;
}

.card-width {
  width: 200px;
}

.list-group-item {
  transition: all 1s;
}

option:checked {
  background-color: #ffffff !important;
}
</style>
