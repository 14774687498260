<template>
  <section id="verificatorSelector">
    <b-row class="ml-1 mt-1 mr-1">
      <b-col style="text-align: justify;">
        <h1>{{ $t("verification") }}</h1>
        <h5 class="text-primary">
          {{ $t("conectores") }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="height: fit-content;"
          :disabled="disabled"
          variant="success"
          @click="seleccionar"
        >
          {{
            $t("dataGeneric.save")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="align-items-center mb-3 mt-3">
      <b-col cols="6">
        <b-form-input
          v-model="buscar"
          :disabled="showClick"
          :placeholder="$t('slider.searchThreeCaracConect')"
          minlength="3"
        />
      </b-col>
    </b-row>
    <b-row v-if="allUserVerificator.length == 0 && buscar.length > 2 && showList != true">
      <b-col
        cols="6"
        class="text-center pr-5"
      >
        <h5 cols="6">
          {{ $t('subscriptions.notFound') }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between align-items-center">
      <b-col class="text-left">
        <b-overlay
          v-if="buscar.length > 2 && allUserVerificator.length == 0"
          variant="transparent"
          :show="showList"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          tag="ul"
          class="mt-1"
        />
        <h5
          v-if="allUserVerificator.length > 0"
          class="text-muted"
        >
          <span>
            {{ $t("common.selList") }}
          </span>
        </h5>
        <span
          v-if="buscar.length < 3 && allUserVerificator.length == 0"
          class="text-muted"
        >
          {{ $t('slider.searchThreeCaracConect') }}
        </span>
      </b-col>
    </b-row>
    <b-row style="height:513px">
      <b-col
        style="height:504px"
        class="scroll-list list-group list-group-flush "
      >
        <b-overlay
          variant="transparent"
          :show="showClick"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          tag="ul"
          class="mt-1"
        >
          <b-list-group-item
            v-for="res in allUserVerificator"
            v-show="allUserVerificator.length != 0"
            :id="res.node.id"
            :key="res.node.id"
            :active="checkActive(res.node.id)"
            class="cursor-pointer"
            tag="li"
            @click="
              clickRow(res.node)"
            @dblclick="clickRow(res.node,true)"
          >
            <b-row class=" text-justify align-items-center">
              <b-col cols="3">
                <b-img
                  id="avatar"
                  rounded="lg"
                  :src="buildImageUrl(res)"
                />
              </b-col>
              <b-col>
                <b-card-text class="mb-0 font-weight-bold">
                  {{ res.node.name }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="3"
        class="d-flex justify-content-center"
      >
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          class="pt-1"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import { messageError, isEmpty } from '@/store/functions'
import {
  BPagination,
  BCol,
  BRow,
  BImg,
  BButton,
  BListGroupItem,
  BFormInput,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormInput,
    BImg,
    BPagination,
    BListGroupItem,
    BCol,
    BRow,
    BButton,
    BCardText,
    BOverlay,
  },

  data() {
    return {
      showList: false,
      disabled: true,
      showClick: false,
      selected: null,
      selectedMulti: [],
      selectedNodeDetails: {},
      orderOrig: null,
      headers: {},
      userData: getUserData(),
      allUserVerificator: [],
      perPage: 15,
      currentPage: 1,
      rows: null,
      buscar: '',

    }
  },
  watch: {
    buscar(oldValue, newValue) {
      if (newValue !== oldValue) {
        if (this.buscar.length >= 3) {
          this.currentPage = 1
          this.fetchData()
        }
        if (this.buscar.length < 3) {
          this.allUserVerificator = []
        }
        if (this.buscar.length === 0) {
          this.fetchData()
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.fetchData()
  },

  methods: {
    isEmpty,
    seleccionar() {
      this.$emit('data', this.selectedNodeDetails)
    },
    checkActive(id) {
      if (this.selectedMulti.includes(id)) {
        return true
      }
      return false
    },
    buildImageUrl(folder) {
      return folder.node.image == null || folder.node.image.length === 0
        ? noCover
        : folder.node.image
    },
    fetchData() {
      const { headers } = this
      this.showList = true

      axios
        .post('', {
          query: `
         query {
          allUserVerificator(
                  orderBy:"-createdAt",
                  first:${this.perPage},
                  offset:${this.perPage * (this.currentPage - 1)},
                  client:"${this.userData.profile.client.id}",
                  ${this.buscar != null ? `name: "${this.buscar}",` : ''}
                  ){
                pageInfo{
                  hasPreviousPage
                  hasNextPage
                }
                totalCount
                edgeCount
                edges{                  
                    node{
                      id
                      name
                      image
                   }
                  }
                }
              }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)
          this.allUserVerificator = response.data.data.allUserVerificator.edges

          this.rows = response.data.data.allUserVerificator.totalCount
          this.showList = false
        }).catch(error => {
          console.log(error)
        })
    },
    clickRow(node, dbl = false) {
      this.showClick = true
      this.selected = node.id
      const element = document.getElementById(this.selected)

      // Toggle 'active' class for the clicked row
      if (element) {
        element.classList.toggle('active')
      }

      // Check if the node is already selected
      if (this.selectedMulti.includes(this.selected)) {
      // Remove the node from selectedMulti and selectedNodeDetails
        this.selectedMulti = this.selectedMulti.filter(item => item !== this.selected)
        delete this.selectedNodeDetails[this.selected]
      } else {
      // Add the node to selectedMulti and selectedNodeDetails
        this.selectedMulti.push(this.selected)
        this.selectedNodeDetails[this.selected] = {
          id: node.id,
          name: node.name,
        // Add other properties as needed
        }
      }

      // Enable or disable based on the length of selectedMulti
      this.disabled = this.selectedMulti.length === 0

      this.showClick = false
      if (dbl) this.seleccionar()
    },
  },
}
</script>

<style lang="scss">
#verificatorSelector .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
}

#avatar {
  width: 10rem;
  height: 5.5rem;
}

#verificatorSelector .td {
  width: 50px;
  height: 100px;
}

#verificatorSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#verificatorSelector .dropContainer {
  border: 3px dashed;
}

#verificatorSelector .card-width {
  width: 200px;
}

#verificatorSelector .list-group-item {
  transition: all 1s;
}

.check {
  background-color: #212736 !important;
}
</style>
